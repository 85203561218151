.login-content .has-error .input-group-addon {
  color: #f6675d;
  border-color: transparent;
  background-color: transparent;
}

tbody .checkbox.pull-left {
  margin: 0;
}

ul.help-block {
  padding-left: 10px;
}